<template>
  <a-row :gutter="16">
    <a-col :md="24" :lg="16">
      <a-form layout="horizontal" :form="form">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="邮箱">
              <a-input v-decorator="['email']" placeholder="请输入邮箱"/>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input v-decorator="['name',
                {rules:[
                  {type:'string',required:true,message:'用户姓名不能为空'},
                  {type:'string',max:30,message:'用户姓名不能超过30个字符'},
                  ],trigger:'change'}
                ]" placeholder="请输入姓名"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="身份证号">
              <a-input v-decorator="['idCard']" placeholder="请输入身份证号"/>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="出生日期：">
              <a-date-picker v-decorator="['birthday']" style="width: 100%;"></a-date-picker>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="性别：">
              <a-radio-group :options="genderType"
                             v-decorator="['gender',{rules:[{type:'string',required:true,message:'性别为必填项'}],trigger:'change'}]"></a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="个人简介">
              <a-textarea v-decorator="['introduction']" :autosize="{ minRows: 3, maxRows: 5 }" placeholder="请输入个人简介"></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
        <!--<a-form-item label="所在省市">
          <a-cascader :value="formItem.area" :options="options" placeholder="请选择省市"/>
        </a-form-item>-->
        <a-row class="form-row" :gutter="16">
          <a-col :lg="24" :md="12" :sm="24">
            <a-form-item label="家庭地址">
              <a-input v-decorator="['address']" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="手机号">
              <a-input v-decorator="['phone',
                          {rules:[
                            {type:'string', pattern:/^1[0-9]{10}$/, required:true,message:'手机号不合法'},
                            ],trigger:'change'}
                          ]" placeholder="输入手机号码"/>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系电话">
              <a-input v-decorator="['tel',
                {rules:[
                  {type:'string',max:20,message:'电话不能超过30个字符'},
                  ],trigger:'change'}
                ]" placeholder="输入联系电话"/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row" :gutter="16">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="微信号">
              <a-input v-decorator="['wechat']" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="QQ号">
              <a-input v-decorator="['qq']" placeholder=""/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item>
          <a-affix :offsetBottom="10">
            <a-button type="primary" :loading="ifSub" style="float: right" @click="updateUserInfo">更新信息</a-button>
          </a-affix>
        </a-form-item>
      </a-form>
    </a-col>
    <a-col :md="24" :lg="8" :style="{ minHeight: '180px' }">
      <!--<div class="ant-upload-preview" @click="$refs.modal.edit(1)">
        <a-icon type="cloud-upload-o" class="upload-icon"/>
        <div class="mask">
          <a-icon type="plus"/>
        </div>
        <img :src="option.img"/>
      </div>-->
      <a-row type="flex" justify="center" align="top" style="padding-top: 15px;">
        <span style="font-size: large;font-weight: bold;">用户头像</span>
      </a-row>
      <a-row type="flex" justify="center" align="top" style="padding-top: 15px;">
        <a-avatar class="avatar" :size="80" v-if="!currentUser.headImg">{{currentUser.name.substring(0,1)}}</a-avatar>
        <a-avatar class="avatar" :size="80" v-if="currentUser.headImg" :src="bucketUrl+currentUser.headImg"></a-avatar>
      </a-row>
      <a-row type="flex" justify="center" align="top" style="padding-top: 15px;">
        <a-button style="margin: 0 auto;" @click="$refs.modal.edit()">
          <a-icon type="upload"/>
          上传头像
        </a-button>
      </a-row>
    </a-col>
    <avatar-modal ref="modal"  @ok="handleImageSubmitSuccess"></avatar-modal>
  </a-row>
</template>

<script>
  import AvatarModal from './AvatarModal'
  import SERVICE_URLS from '../../api/service.url'
  import { UserDTO, GenderType } from '@/api/vo/settings/userDomain'
  import { mapState } from 'vuex'
  import moment from 'moment'

  export default {
    components: {
      AvatarModal,
    },
    data () {
      return {
        form: this.$form.createForm(this),
        formItem: UserDTO(),
        genderType: GenderType,
        ifSub: false,
        preview: {},
        // visible: false,
        // id: null,
        // options: {
        //   img: '',
        //   autoCrop: true,
        //   autoCropWidth: 200,
        //   autoCropHeight: 200,
        //   fixedBox: true
        // },
        // confirmLoading: false
      }
    },
    computed: {
      ...mapState({
        currentUser: state => state.user.currentUser,
        bucketUrl: state => {
          return state.user.bucketUrl
        }
      })
    },
    mounted () {
      this.setFields()
    },
    methods: {
      setFields (data) {
        Object.assign(this.formItem, this.currentUser)
        this.form.setFieldsValue({
          name: this.formItem.name,
          idCard: this.formItem.idCard,
          gender: this.formItem.gender,
          // phone: this.formItem.phone,
          introduction: this.formItem.introduction,
          // birthday: moment(this.formItem.birthday),
          birthday: this.formItem.birthday ? moment(this.formItem.birthday) : null,
          tel: this.formItem.tel,
          // ↓页面上都没有这个东西！！！，`setVO`不管了
          // motto: this.formItem.motto,
          wechat: this.formItem.wechat,
          qq: this.formItem.qq,
          email: this.formItem.email,
          phone: this.formItem.phone,
          address: this.formItem.address
        })
      },
      setVOFields (values) {
        this.formItem.name = values.name
        this.formItem.idCard = values.idCard
        this.formItem.birthday = values.birthday
        this.formItem.gender = values.gender
        this.formItem.phone = values.phone
        this.formItem.tel = values.tel
        this.formItem.motto = values.motto
        this.formItem.wechat = values.wechat
        this.formItem.qq = values.qq
        this.formItem.email = values.email
        this.formItem.introduction = values.introduction
        this.formItem.address = values.address
      },
      updateUserInfo () {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.setVOFields(values)
            this.$http(this, {
              url: SERVICE_URLS.user.ownUpdate,
              data: this.formItem,
              params: {
                id: this.formItem.id
              },
              loading: 'ifSub',
              noTips: true,
              success: (data) => {
                this.$message.success('更新成功')
                this.$store.commit('SET_CURRENT_USER', data.body)
              }
            })
          }
        })
      },
      handleImageSubmitSuccess (fileUrl) {
        // 修改头像
        // console.log('ok回调函数', fileUrl)
        this.$http(this, {
          url: SERVICE_URLS.user.updateHeadimg,
          data: {
            headImg: fileUrl
          },
          loading: 'ifSub',
          noTips: true,
          success: (data) => {
            this.$message.success('头像修改成功')
            this.$store.commit('SET_CURRENT_USER', data.body)
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>

  .avatar-upload-wrapper {
    height: 200px;
    width: 100%;
  }

  .ant-upload-preview {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 180px;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;

    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .mask {
      opacity: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      transition: opacity 0.4s;

      &:hover {
        opacity: 1;
      }

      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }

    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
    }
  }
</style>
